@import '@/app/variables';

.wrapper {
  position: absolute;
  z-index: 10;
  width: 100%;
}

.inner {
  display: flex;
  align-items: center;
  padding-top: 33px;
  position: relative;
  @media(max-width: $bp-m) {
    padding-top: 20px;
  }
}

.search-wrapper {
  width: 560px;
  margin-left: 60px;
}

.login-wrapper {
  margin-left: auto;
}

.hamburger {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 7px;
  border: 1px solid var(--text-color-inverted);
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.3s;
  cursor: pointer;
  margin-left: auto;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  @media(pointer: coarse) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  & > button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.hamburger.opened {
  &:after {
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L15 15' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 1L1 15' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.hamburger.closed {
  &:after {
    width: 20px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='14' viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.9444 5.89062H1.05556C0.472588 5.89062 0 6.36321 0 6.94618V7.05729C0 7.64026 0.472588 8.11285 1.05556 8.11285H18.9444C19.5274 8.11285 20 7.64026 20 7.05729V6.94618C20 6.36321 19.5274 5.89062 18.9444 5.89062Z' fill='white'/%3E%3Cpath d='M18.9444 11.4453H1.05556C0.472588 11.4453 0 11.9179 0 12.5009V12.612C0 13.1949 0.472588 13.6675 1.05556 13.6675H18.9444C19.5274 13.6675 20 13.1949 20 12.612V12.5009C20 11.9179 19.5274 11.4453 18.9444 11.4453Z' fill='white'/%3E%3Cpath d='M18.9444 0.335938H1.05556C0.472588 0.335938 0 0.808526 0 1.39149V1.5026C0 2.08557 0.472588 2.55816 1.05556 2.55816H18.9444C19.5274 2.55816 20 2.08557 20 1.5026V1.39149C20 0.808526 19.5274 0.335938 18.9444 0.335938Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}

.mobile-menu-wrapper {
  position: fixed;
  min-height: 100dvh;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to top, var(--primary-customer-color), var(--primary-customer-light-color)); //TODO
}

.mobile-menu-inner {
  margin-top: 204px;
  padding: 0 20px 20px;
}

.mobile {
  display: none;
  @media(max-width: $bp-m) {
    display: block;
  }
}

.desktop {
  display: block;
  @media(max-width: $bp-m) {
    display: none;
  }
}

.phoneLink {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: var(--fz-24);
  line-height: 40px;
  font-weight: 700;
  color: var(--text-color-inverted);
  margin-left: 60px;
  transition: opacity 0.3s;
}

.phoneLinkMenu {
  @media(max-width: $bp-m) {
    margin: 40px 0 0;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

}

.phoneLinkMobile {
  @media(max-width: $bp-m) {
    font-size: var(--fz-20);
    line-height: 34px;
    gap: 8px;
    margin: 16px 0 0 0;
  }

  img {
    @media(max-width: $bp-m) {
      width: 24px;
      height: 24px;
    }
  }
}
